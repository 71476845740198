<template>
  <div class="my">
    <div class="headContainer">
      <div class="phone">{{phone}}</div>
      <!-- <progress-bar :data_score="rate"  /> -->
    </div>
    <div class="mainContainer">
      <van-cell-group>
        <van-cell to="/my/mydata" center>
          <div slot="icon">
            <van-image class="dataimg margin_10" width="30" height="30" :src="Aimg" />
          </div>
          <div class="title" slot="title">{{$t('MYDATA')}}</div>
          <van-icon size="25" slot="right-icon" name="arrow" />
        </van-cell>
        <van-cell to="/my/myborrow" center>
          <div slot="icon">
            <van-image class="dataimg margin_10" width="30" height="30" :src="Bimg" />
          </div>
          <div class="title" slot="title">{{$t('MYBORROW')}}</div>
          <van-badge slot="right-icon" :dot="isdot">
            <van-icon size="25" name="arrow" />
          </van-badge>
        </van-cell>
        <van-cell to="/my/myrefund" center>
          <div slot="icon">
            <van-image class="dataimg margin_10" width="30" height="30" :src="Cimg" />
          </div>
          <div class="title" slot="title">{{$t('MYREFUND')}}</div>
          <van-icon size="25" slot="right-icon" name="arrow" />
        </van-cell>
        <!-- <van-cell to="/my/mydata/openlevel" center>
          <div slot="icon">
            <van-image class="dataimg margin_10" width="30" height="30" :src="Dimg" />
          </div>
          <div class="title" slot="title">{{$t('OPENVIP')}}</div>
          <van-icon size="25" slot="right-icon" name="arrow" />
        </van-cell> -->
        <van-cell to="/my/wywithdrwdeposit" center>
          <div slot="icon">
            <van-image class="dataimg margin_10" width="30" height="30" :src="Eimg" />
          </div>
          <div class="title" slot="title">{{$t('MYWITHDRAWDEPOSIT')}}</div>
            <van-badge slot="right-icon" :dot="isdotw">
              <van-icon size="25" name="arrow" />
            </van-badge>
        </van-cell>
        <!-- <van-cell @click="neterAPPv" center>
          <div slot="icon">
            <van-image class="dataimg margin_10" width="30" height="30" :src="appimg" />
          </div>
          <div class="title" slot="title">{{$t('LOADAPP')}}</div>
          <van-icon size="25" slot="right-icon" name="arrow" />
        </van-cell> -->
        <van-cell to="/my/apassword" center>
          <div slot="icon">
            <van-image class="dataimg margin_10" width="30" height="30" :src="Pimg" />
          </div>
          <div class="title" slot="title">{{$t('CHANGEPASSWORD')}}</div>
          <van-icon size="25" slot="right-icon" name="arrow" />
        </van-cell>
        <van-cell @click="getline" center>
          <div slot="icon">
            <van-image class="dataimg margin_10" width="30" height="30" :src="lineImg" />
          </div>
          <div class="title" slot="title">{{$t('CONTACTCUSTOMERSERVICE')}}</div>
          <van-icon size="25" slot="right-icon" name="arrow" />
        </van-cell>
      </van-cell-group>
    </div>
    <div class="subBox">
      <van-button @click="sub" class="sub" color="#FF910B" type="primary" block>{{$t('LOGOUT')}}</van-button>
    </div>
  </div>
</template>

<script>
import { romoveItem } from '../../utils/storage'
import { Dialog, Toast } from 'vant'
import { logOut, myInfo, getunreadInformation, getLineUrl } from '@/api/my'
// import progressBar from './progress-bar.vue'
import './css/index.css'
export default {
  name: 'my',
  data () {
    return {
      Aimg: require('./img/m_07.png'),
      Bimg: require('./img/m_10.png'),
      Cimg: require('./img/m_12.png'),
      Dimg: require('./img/hm_14.png'),
      Eimg: require('./img/tixian.png'),
      appimg: require('./img/APP.png'),
      Pimg: require('./img/alterPassword.png'),
      lineImg: require('./img/LineMessenger.png'),
      phone: '',
      pictureUrl: require('../Login/img/qdlogo.jpg'),
      VIPUrl: require('./img/VIP.png'),
      isdot: false,
      isdotw: false,
      rate: 0
    }
  },
  components: {
    // progressBar
  },
  methods: {
    async sub () {
      Dialog.confirm({
        title: this.$t('LOGOUT'),
        cancelButtonText: this.$t('CANCEL'),
        confirmButtonText: this.$t('CONFIRM')
      })
        .then(() => {
          // on confirm
          logOut().then((data) => {
            if (data.data.code === 200) {
              Toast.loading({
                message: this.$t('AWAIT'),
                forbidClick: true,
                loadingType: 'spinner'
              })
              romoveItem('token')
              setTimeout(() => {
                this.$router.push({
                  name: 'login'
                })
              }, 3000)
            }
            if (data.data.code === 500) {
              Toast.loading({
                message: data.data.message,
                forbidClick: true,
                loadingType: 'spinner'
              })
              setTimeout(() => {
                this.$router.push({
                  name: 'login'
                })
              }, 3000)
            }
          })
        })
        .catch(() => {
          console.log(1234)
          // on cancel
        })
    },
    async loadMyinfo () {
      try {
        const { data } = await myInfo()
        if (data.code === 200) {
          this.phone = data.data.phone
          // this.currentRate = 10
          this.rate = parseFloat(data.data.creditScore)
          if (this.pictureUrl === '') {
            this.pictureUrl = data.data.pictureUrl
          } else {
            this.pictureUrl = require('../Login/img/qdlogo.jpg')
          }
          // if (data.data.vip === '1') {
          //   this.isVip = true
          // } else {
          //   this.isVip = false
          // }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async unread () {
      const { data } = await getunreadInformation()
      if (data.code === 200) {
        if (data.data.loan) {
          this.isdot = true
        }
        if (data.data.withdrawal) {
          this.isdotw = true
        }
      }
    },
    neterAPPv () {
      location.href = 'http://download.qd5.cc/'
    },
    async getline () {
      try {
        // console.log(document.domain)
        const { data } = await getLineUrl({
          // url: document.domain
        })
        if (data.code === 200) {
          // console.log(data.data)
          location.href = data.data
        }
        // console.log(data)
      } catch (error) {
        console.log(error)
      }
    }
  },
  created () {
    this.loadMyinfo()
    this.unread()
  }
}
</script>

<style scoped>
.my >>> .van-circle {
  box-shadow: 0 0 5px #021762;
  border-radius: 200px;
}
.Rate {
  padding-top: 25px;
  font-size: 20Px;
  color: #000;
}
.rate-text {
  color: #000;
  font-size: 12Px;
}
.title {
  font-size: 16px;
}
.headContainer {
  overflow: hidden;
  /* background-color:#FF910B; */
  background: url('./img/my-head-bg.png') no-repeat center;
  background-size: cover;
  min-height: 155px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
}
.phone {
  text-align: center;
  font-size: 25px;
  color: #fff;
  padding-top: 20px;
  font-weight: 600;
}
.mainContainer {
  margin-top: 10px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid#e4e4e4;
}
.margin_10 {
  margin-right: 10px;
}
.subBox {
  height: 150px;
}
.sub {
  font-size: 18px;
  width: 320px;
  margin: 50px auto;
  /* margin-top: 50px; */
  /* margin-bottom: 80px; */
}
</style>
